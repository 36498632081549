import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProductsTotal } from '../../../../store/selectors';
import * as CartActions from '../../../../store/modules/cart/actions';
import { formatNumberToReal, formatRealToNumber } from '../../../../utils/formatting';
import {
  UIInput,
  UIButton,
  UIInputCurrency,
  UICustomInputLabel,
} from '../../../UI';

const CartFormAddition = ({
  productsTotal,
  isOnlineCommand,
  additionalValue,
  addAdditionValue,
}) => {
  const [total, setTotal] = useState(null);
  const [type, setType] = useState('fixed');
  const [discountPercentage, setDiscountPercentage] = useState(null);

  useEffect(() => {
    if (additionalValue?.type === 'percentage') {
      setType('percentage');
      setDiscountPercentage(additionalValue?.value);
      setTotal((productsTotal * additionalValue?.value) / 100);
    }

    if (additionalValue?.type === 'fixed') {
      setType('fixed');
      setTotal(additionalValue?.value);
      setDiscountPercentage((additionalValue?.value / productsTotal) * 100);
    }
  }, [additionalValue, productsTotal]);

  const handleChangeDiscount = (newPercentage) => {
    const value = Number(newPercentage);
    const totalDiscount = (productsTotal * value) / 100;

    setDiscountPercentage(value);
    setTotal(totalDiscount);
    addAdditionValue({
      type: 'percentage',
      value: newPercentage,
    });
  };

  const handleChangeTotal = (value) => {
    const formattedTotal = formatRealToNumber(value);

    setDiscountPercentage((formattedTotal / productsTotal) * 100);
    setTotal(formattedTotal);
    addAdditionValue({
      type: 'fixed',
      value: formattedTotal,
    });
  };

  if (!isOnlineCommand) {
    return null;
  }

  return (
    <>
      <UICustomInputLabel
        labelTitle="Acréscimo"
      />

      <div className="tw-flex tw-gap-1">
        <UIButton
          outline
          customWidth="50"
          text={type === 'percentage' ? '%' : 'R$'}
          onClick={() => {
            setType((prevState) => {
              const newValue = prevState === 'percentage' ? 'fixed' : 'percentage';
              return newValue;
            });
          }}
        />

        {type === 'fixed' && (
          <UIInputCurrency
            value={total}
            setValue={handleChangeTotal}
          />
        )}

        {type === 'percentage' && (
          <UIInput
            type="number"
            placeholder="Ex: 1"
            value={discountPercentage}
            setValue={handleChangeDiscount}
            infoMessage={`Acréscimo de ${formatNumberToReal(total)}`}
          />
        )}
      </div>
    </>
  );
};

CartFormAddition.propTypes = {
  productsTotal: PropTypes.number.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  addAdditionValue: PropTypes.func.isRequired,
  additionalValue: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  productsTotal: getProductsTotal(state),
  additionalValue: state.cart.additionValue,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormAddition);
