import React from 'react';
import { useParams } from 'react-router-dom';
import { UIButton } from '../../UI';

import './CartAddItem.scss';

const CartAddItem = () => {
  const { establishment } = useParams();

  return (
    <UIButton
      outline
      text="Adicionar mais itens"
      className="CartAddItem mb-20 lg:tw-hidden"
      linkTo={`/${establishment}/produtos`}
    />
  );
};

export default CartAddItem;
