import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import PropTypes from 'prop-types';
import { FirebaseProduct, FirebaseAdditional } from '../../firebase/functions';
import { ProductLoader } from './Loader';

import './Product.scss';

import ProductHeader from './Header';
import ProductAddToCart from './AddToCart';
import ProductDescription from './Description';
import ProductObservation from './Observation';
import ProductSelectionGroup from './SelectionGroup';
import { getProductPriceByInfos } from '../../utils/getProductValues';

const Product = ({ customProductId, closeModal }) => {
  const { productId } = useParams();
  const productIdToUse = customProductId || productId;
  const [product, setProduct] = useState({});
  const [observation, setObservation] = useState('');
  const [customProduct, setCustomProduct] = useState({});

  // Este variations na vdd é o customPrice do produto.
  // A story FM-229 é focada em mudarmos o nome de como salvamos ela no produto
  const [customVariations, setCustomVariations] = useState([]);
  const [selectedVariations, setSelectedVariations] = useState([]);

  const [additionals, setAdditionals] = useState([]);
  const [selectedAdditionals, setSelectedAdditionals] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function init() {
      setLoading(true);
      const productOnFirebase = await FirebaseProduct.getProductById(productIdToUse);
      if (productOnFirebase) {
        setProduct(productOnFirebase);
        if (productOnFirebase?.variations?.length > 0) {
          const filteredVariations = productOnFirebase.variations.filter(
            (variation) => !variation?.paused,
          );
          setCustomVariations(filteredVariations);
        }

        if (productOnFirebase?.additionals?.length > 0) {
          const allAdditionals = await FirebaseAdditional.getAdditionalsByIds(
            productOnFirebase.additionals,
          );

          const additionalWithAllValidOptions = allAdditionals.reduce((acc, additional) => {
            const validOptions = additional.options.filter((option) => !option.paused);

            if (validOptions.length > 0) {
              acc.push(additional);
            }

            return acc;
          }, []);

          setAdditionals(additionalWithAllValidOptions);
        }

        ReactPixel.track('ViewContent', {
          content_type: 'product',
          content_name: productOnFirebase.name,
          value: getProductPriceByInfos(productOnFirebase),
          currency: 'BRL',
        });
      }
      setLoading(false);
    }
    init();
  }, [productIdToUse]);

  useEffect(() => {
    const newCustomProduct = {
      ...product,
      observation,
      selectedVariations,
      selectedAdditionals,
    };

    setCustomProduct(newCustomProduct);
  }, [product, observation, selectedVariations, selectedAdditionals]);

  if (Object.keys(product).length <= 0) return null;

  const {
    name,
    image,
    description,
  } = product;

  if (loading) {
    return <ProductLoader />;
  }

  return (
    <div className="Product">
      <ProductHeader
        productName={name}
        productImage={image}
        productDescription={description}
      />

      <ProductDescription
        productName={name}
        productDescription={description}
      />

      {customVariations && customVariations.length > 0 && (
        <ProductSelectionGroup
          header={{
            min: 1,
            max: 1,
            name: '',
            repeatOptions: false,
          }}
          dontShowThePlusIcon
          options={customVariations}
          setAllSelectedAdditionals={setSelectedVariations}
        />
      )}

      {additionals && additionals.length > 0 && (
        additionals.map((additional, index) => (
          <ProductSelectionGroup
            position={index + 1}
            header={additional.infos}
            key={additional.infos.name}
            options={additional.options}
            setAllSelectedAdditionals={setSelectedAdditionals}
          />
        ))
      )}

      <ProductObservation
        observation={observation}
        setObservation={setObservation}
      />

      <ProductAddToCart
        closeModal={closeModal}
        product={customProduct}
        additionals={additionals}
      />
    </div>
  );
};

Product.propTypes = {
  closeModal: PropTypes.func,
  customProductId: PropTypes.string,
};

Product.defaultProps = {
  closeModal: null,
  customProductId: '',
};

export default Product;
