import React from 'react';
import PropTypes from 'prop-types';

import './CartValues.scss';
import { connect } from 'react-redux';
import {
  getDeliveryRate,
  getFormattedCartTotal,
  getParsedDiscountValue,
  getParsedAdditionValue,
  getFormattedCouponValue,
  getFormattedProductsTotal,
  getIfActiveCouponIsValidToUse,
  getParsedAdditionalValueFromPaymentMethod,
} from '../../../store/selectors';
import { UIPrice } from '../../UI';
import { formatNumberToReal } from '../../../utils/formatting';

const CartValues = ({
  cartTotal,
  deliveryRate,
  additionValue,
  productsTotal,
  discountValue,
  formattedCouponValue,
  activeCouponIsValidToUse,
  additionalValueFromPaymentMethod,
}) => (
  <div className="CartValues mb-20">
    {(activeCouponIsValidToUse || deliveryRate > 0) && (
      <div className="CartValues__value">
        <span>Subtotal</span>
        <span>{productsTotal}</span>
      </div>
    )}

    {deliveryRate > 0 && (
      <div className="CartValues__value">
        <span>Taxa de entrega</span>
        <UIPrice
          smallType
          price={deliveryRate}
          free={formattedCouponValue === 'FRETE GRÁTIS'}
        />
      </div>
    )}

    {activeCouponIsValidToUse && (
      <div className="CartValues__value">
        <span>Cupom</span>
        {formattedCouponValue === 'FRETE GRÁTIS' ? (
          <span>
            {formattedCouponValue}
          </span>
        ) : (
          <span className="cupom">
            -
            {' '}
            {formattedCouponValue}
          </span>
        )}
      </div>
    )}

    {discountValue > 0 && (
      <div className="CartValues__value">
        <span>Desconto</span>
        {'- '}
        {formatNumberToReal(discountValue)}
      </div>
    )}

    {additionValue > 0 && (
      <div className="CartValues__value">
        <span>Acréscimo</span>
        {'+ '}
        {formatNumberToReal(additionValue)}
      </div>
    )}

    {additionalValueFromPaymentMethod > 0 && (
      <div className="CartValues__value">
        <span>Acréscimo forma de pagamento</span>
        {'+ '}
        {formatNumberToReal(additionalValueFromPaymentMethod)}
      </div>
    )}

    <div className="CartValues__value CartValues__total">
      <span>Total</span>
      <span>{cartTotal}</span>
    </div>
  </div>
);

CartValues.propTypes = {
  deliveryRate: PropTypes.number,
  formattedCouponValue: PropTypes.string,
  cartTotal: PropTypes.string.isRequired,
  additionValue: PropTypes.number.isRequired,
  discountValue: PropTypes.number.isRequired,
  productsTotal: PropTypes.string.isRequired,
  activeCouponIsValidToUse: PropTypes.bool.isRequired,
  additionalValueFromPaymentMethod: PropTypes.number.isRequired,
};

CartValues.defaultProps = {
  deliveryRate: null,
  formattedCouponValue: null,
};

const mapStateToProps = (state) => ({
  deliveryRate: getDeliveryRate(state),
  cartTotal: getFormattedCartTotal(state),
  additionValue: getParsedAdditionValue(state),
  discountValue: getParsedDiscountValue(state),
  productsTotal: getFormattedProductsTotal(state),
  formattedCouponValue: getFormattedCouponValue(state),
  activeCouponIsValidToUse: getIfActiveCouponIsValidToUse(state),
  additionalValueFromPaymentMethod: getParsedAdditionalValueFromPaymentMethod(state),
});

export default connect(mapStateToProps, null)(CartValues);
