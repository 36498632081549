import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CartActions from '../../../../store/modules/cart/actions';
import { getProductsTotal } from '../../../../store/selectors';
import {
  UIButton,
  UICustomInputLabel,
  UIInputCurrency,
  UIInput,
} from '../../../UI';
import { formatNumberToReal, formatRealToNumber } from '../../../../utils/formatting';

const CartFormDiscount = ({
  discountValue,
  productsTotal,
  isOnlineCommand,
  addDiscountValue,
}) => {
  const [total, setTotal] = useState(null);
  const [type, setType] = useState('fixed');
  const [discountPercentage, setDiscountPercentage] = useState(null);

  useEffect(() => {
    if (discountValue?.type === 'percentage') {
      setType('percentage');
      setDiscountPercentage(discountValue?.value);
      setTotal((productsTotal * discountValue?.value) / 100);
    }

    if (discountValue?.type === 'fixed') {
      setType('fixed');
      setTotal(discountValue?.value);
      setDiscountPercentage((discountValue?.value / productsTotal) * 100);
    }
  }, [discountValue, productsTotal]);

  const handleChangeDiscount = (newPercentage) => {
    const value = Number(newPercentage) > 100 ? 100 : newPercentage;
    const totalDiscount = (productsTotal * value) / 100;

    setDiscountPercentage(value);
    setTotal(totalDiscount);
    addDiscountValue({
      type: 'percentage',
      value: newPercentage,
    });
  };

  const handleChangeTotal = (value) => {
    const formattedTotal = formatRealToNumber(value);
    const newTotal = formattedTotal > productsTotal ? productsTotal : formattedTotal;

    setDiscountPercentage((newTotal / productsTotal) * 100);
    setTotal(formattedTotal);
    addDiscountValue({
      type: 'fixed',
      value: newTotal,
    });
  };

  if (!isOnlineCommand) {
    return null;
  }

  return (
    <>
      <UICustomInputLabel
        labelTitle="Desconto"
      />

      <div className="tw-flex tw-gap-1">
        <UIButton
          outline
          customWidth="50"
          text={type === 'percentage' ? '%' : 'R$'}
          onClick={() => {
            setType((prevState) => {
              const newValue = prevState === 'percentage' ? 'fixed' : 'percentage';
              return newValue;
            });
          }}
        />

        {type === 'fixed' && (
          <UIInputCurrency
            value={total}
            setValue={handleChangeTotal}
            errorMessage={productsTotal < total ? 'Desconto maior que o valor dos itens' : ''}
          />
        )}

        {type === 'percentage' && (
          <UIInput
            type="number"
            placeholder="Ex: 1"
            value={discountPercentage}
            setValue={handleChangeDiscount}
            infoMessage={`Desconto de ${formatNumberToReal(total)}`}
          />
        )}
      </div>
    </>
  );
};

CartFormDiscount.propTypes = {
  productsTotal: PropTypes.number.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  addDiscountValue: PropTypes.func.isRequired,
  discountValue: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  productsTotal: getProductsTotal(state),
  discountValue: state.cart.discountValue,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormDiscount);
