import { createSelector } from 'reselect';
import { getProductsTotal } from '../products';
import { getDeliveryRate } from '../deliveryRate';
import { formatNumberToReal } from '../../../utils/formatting';
import { getCouponValue, getIfActiveCouponIsValidToUse } from '../coupon';

const getPorcentageParsedValue = (porcentage, total) => (
  Number(((total * porcentage) / 100).toFixed(2))
);

const getAdditionValue = (state) => state.cart.additionValue;
const getDiscountValue = (state) => state.cart.discountValue;
const getAdditionalValueFromPaymentMethod = (state) => state.cart.additionalValueFromPaymentMethod;

export const getParsedAdditionValue = createSelector(
  [
    getProductsTotal,
    getAdditionValue,
  ],
  (
    productsTotal,
    additionValue,
  ) => {
    if (additionValue.type === 'percentage') {
      return getPorcentageParsedValue(additionValue.value, productsTotal);
    }

    return additionValue.value;
  },
);

export const getParsedDiscountValue = createSelector(
  [
    getProductsTotal,
    getDiscountValue,
  ],
  (
    productsTotal,
    discountValue,
  ) => {
    if (discountValue.type === 'percentage') {
      return getPorcentageParsedValue(discountValue.value, productsTotal);
    }

    return discountValue.value;
  },
);

export const getCartTotalBeforePaymentTaxes = createSelector(
  [
    getProductsTotal,
    getDeliveryRate,
    getCouponValue,
    getIfActiveCouponIsValidToUse,
    getParsedAdditionValue,
    getParsedDiscountValue,
  ],
  (
    productsTotal,
    deliveryRate,
    couponValue,
    activeCouponIsValidToUse,
    additionValue,
    discountValue,
  ) => {
    if (couponValue && activeCouponIsValidToUse) {
      if (couponValue === 'FRETE GRÁTIS') {
        return productsTotal + additionValue - discountValue;
      }
      return (
        productsTotal + deliveryRate - couponValue + additionValue - discountValue
      );
    }

    return productsTotal + deliveryRate + additionValue - discountValue;
  },
);

export const getParsedAdditionalValueFromPaymentMethod = createSelector(
  [
    getCartTotalBeforePaymentTaxes,
    getAdditionalValueFromPaymentMethod,
  ],
  (
    productsTotal,
    additionValue,
  ) => {
    if (additionValue.type === 'percentage') {
      return getPorcentageParsedValue(additionValue.value, productsTotal);
    }

    return additionValue.value;
  },
);

export const getCartTotal = createSelector(
  [
    getCartTotalBeforePaymentTaxes,
    getParsedAdditionalValueFromPaymentMethod,
  ],
  (
    cartTotalBeforePaymentTaxes,
    additionalValueFromPaymentMethod,
  ) => cartTotalBeforePaymentTaxes + additionalValueFromPaymentMethod,
);

export const getFormattedCartTotal = createSelector(
  [getCartTotal],
  (cartTotal) => formatNumberToReal(cartTotal),
);
