import { formatNumberToReal } from '../../formatting';

export const createWhatsMessageValues = (
  deliveryType,
  delivery,
  itemsTotal,
  cartTotal,
  coupon,
  additionValue,
  discountValue,
  additionalValueFromPaymentMethod,
) => {
  let finalText = '';
  if (delivery?.rate > 0 || coupon?.name) {
    finalText += `*Subtotal*: ${itemsTotal} \n`;
  }

  if (deliveryType.label === 'delivery') {
    if (delivery.rateToBeAgreed) {
      finalText += '*Taxa de entrega*: A combinar \n';
    } else {
      finalText += `*Taxa de entrega*: +${delivery.formattedRate} \n`;
    }
  }

  if (coupon?.name) {
    if (coupon?.parsedValue === 'FRETE GRÁTIS') {
      finalText += `*Cupom ${coupon?.name}*: -${delivery.formattedRate} (FRETE GRÁTIS) \n`;
    } else {
      finalText += `*Cupom ${coupon?.name}*: -${formatNumberToReal(coupon?.parsedValue)} \n`;
    }
  }

  if (additionValue > 0) {
    finalText += `*Valor Adicional*: ${formatNumberToReal(additionValue)} \n`;
  }

  if (discountValue > 0) {
    finalText += `*Desconto*: ${formatNumberToReal(discountValue)} \n`;
  }

  if (additionalValueFromPaymentMethod > 0) {
    finalText += `*Adicional meio de pagamento*: ${formatNumberToReal(additionalValueFromPaymentMethod)} \n`;
  }

  if (delivery.rateToBeAgreed && coupon?.parsedValue !== 'FRETE GRÁTIS') {
    finalText += `*Total sem a taxa de entrega*: ${cartTotal}`;
  } else {
    finalText += `*Total*: ${cartTotal}`;
  }

  return finalText;
};
