import axios from 'axios';
import { ASAAS_STATUS } from './consts';

export const getPlanIsActive = async (firebaseEstablishmentInfos) => {
  const { planStatus, subscription } = firebaseEstablishmentInfos;

  if (subscription?.id) {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/subscriptions?subscriptionID=${subscription.id}`,
      );

      if (data?.length) {
        const overduePayment = data.find(
          (item) => item.status === ASAAS_STATUS.OVERDUE,
        );

        if (overduePayment) {
          return false;
        }
      }
    } catch (err) {
      return true;
    }
  }

  if (!planStatus) return true;

  if (
    planStatus === 'canceled'
    || planStatus === 'pending-plan-choice'
    || planStatus === 'blocked'
  ) {
    return false;
  }

  return true;
};
