import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './MenuItem.scss';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams } from 'react-router-dom';
import { MenuItemPrice } from './Price';
import { screenSizes } from '../../../utils/screensSizes';
import { UIModal } from '../../UI';
import Product from '../../Product';

const MenuItem = ({
  item,
}) => {
  const [showModal, setShowModal] = useState(false);
  const isMediumScreen = useMediaQuery({ query: screenSizes.md });
  const { establishment } = useParams();
  const {
    id, image, description, name,
  } = item;
  const getCustomRoute = () => `/${establishment}/produtos/${id}`;

  if (isMediumScreen) {
    return (
      <>
        <div className="MenuItem tw-cursor-pointer" aria-hidden="true" onClick={() => setShowModal(true)}>
          <div className="MenuItem__infos">
            <h2 className="infos__name">{name}</h2>
            <p className="infos__description">
              {description}
            </p>

            <MenuItemPrice item={item} />
          </div>

          {image && (
            <img className="MenuItem__image" src={image} alt="Americana" />
          )}
        </div>

        <UIModal
          modalTitle={name}
          showModal={showModal}
          setShowModal={() => setShowModal((prevState) => !prevState)}
        >
          <Product
            customProductId={id}
            closeModal={() => setShowModal((prevState) => !prevState)}
          />
        </UIModal>
      </>
    );
  }

  return (
    <Link to={getCustomRoute()} className="MenuItem">
      <div className="MenuItem__infos">
        <h2 className="infos__name">{name}</h2>
        <p className="infos__description">
          {description}
        </p>
        <MenuItemPrice item={item} />
      </div>

      {image && (
        <img className="MenuItem__image" src={image} alt="Americana" />
      )}
    </Link>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    menu: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    amount: PropTypes.number,
    description: PropTypes.string,
    observation: PropTypes.string,
    priceIsNotFixed: PropTypes.bool,
    promotionalPrice: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.string),
    variations: PropTypes.arrayOf(PropTypes.object),
    priceType: PropTypes.oneOf(['single', 'multiple']),
    selectedVariations: PropTypes.objectOf(PropTypes.object),
  }),
};

MenuItem.defaultProps = {
  item: {},
};

export default MenuItem;
